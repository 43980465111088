import { getBaseUrl } from "utils.js"
import { hideBlankSlate, showBlankSlate } from "./utils";

const maxLayoutLength = 2000;

const htmlToElement = (html) => {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
};

const addAll = () => {
  loadConfig(Object.keys(window.devices).join("|"));
}

const addDevice = (deviceId, position=null, closed=false) => {
  var device = window.devices[deviceId];
  if (device == null)
    device = window.devices[window.deviceIds[deviceId]]; // maybe a short code
  if (device == null)
    return;

  let x = null, y = null, r = null;
  if (position != null) {
    x = position[0];
    y = position[1];
    r = position[2] || 0;
  }
  let element;
  return window.engine.renderFile("device", device)
    .then((deviceHtml) => {
      element = htmlToElement(deviceHtml);
      document.getElementById("background").append(element);
    }).then(() => {
      // Stimulus has responded to the change by now
      if (x != null) {
        element.moveController.moveTo(parseInt(x) || 0, parseInt(y) || 0);
        element.moveController.rotateTo(parseInt(r) || 0);
      }
      if (closed) {
        element.deviceController.close(false);
      }
    });
}

const addDeviceAndUpdateLocation = (deviceId, position=null, closed=false) => {
  return addDevice(deviceId, position, closed).then(() => {
    updateLocation();
  });
}

// config loos like: "3ds|2ds:35,22"
const loadConfig = (configuration, saveState = false, replaceState = false) => {
  if (configuration == null) {
    window.toolsController.removeAll(false);
    showBlankSlate();
    return;
  }

  hideBlankSlate();

  configuration = configuration.replaceAll("%7C", "|").replaceAll("|", "&");

  console.log("Loading: " + configuration);
  var positionsFound = false;

  window.toolsController.removeAll(false);
  var parts = configuration.split("~");
  var devices = parts.shift().split("&");
  var layout = "pack";
  window.displayController.setPlatform();
  window.displayController.setScaling();
  for (var part of parts) {
    if (part == "stack") {
      layout = "stack";
    } else if (part == "rack") {
      layout = "rack";
    } else if ([...window.displayController.platformTarget.options].map((v) => v.value).includes(part)) {
      window.displayController.setPlatform(part);
    } else if ([...window.displayController.scalingTarget.options].map((v) => v.value).includes(part)) {
      window.displayController.setScaling(part);
    }
  }

  if (configuration == "ALL") {
    devices = Object.keys(window.devices);
  }

  var promises = [];

  for (var deviceDefinition of devices) {
    const data = deviceDefinition.split(":");
    var deviceId = data[0];
    var closed = false;
    if (deviceId.includes("!")) {
      closed = true;
      deviceId = deviceId.replace("!", "");
    }
    if (data.length >= 2) {
      positionsFound = true;
      const position = data[1].split(",");
      promises.push(addDevice(deviceId, position, closed));
    } else {
      promises.push(addDevice(deviceId, null, closed));
    }
  }

  Promise.all(promises).then(() => {
    if (!positionsFound)
      if (layout == "pack")
        window.toolsController.packDevices(false);
      else if (layout == "rack")
        window.toolsController.rackDevices(false);
      else if (layout == "stack")
        window.toolsController.stackDevices(false);
    if (saveState)
      updateLocation(replaceState);
  });
}

const getLayout = () => {
  let l = window.toolsController.getDevices().map((device) => device.getDefinition()).join("&");
  if (l.length > maxLayoutLength) {
    console.log(l.length);
    l = window.toolsController.getDevices().map((device) => device.getDefinition(true)).join("&");
    console.log(l.length);
  }
  return l + getDisplay();
}

const getLayoutUrl = () => {
  return getBaseUrl() + "?" + getLayout();
}

const getDisplay = () => {
  var display = `~${window.displayController.getPlatform()}~${window.displayController.getScaling()}`;
  // remove defaults
  return display.replace("~details","").replace("~fit", "");
}

const getList = () => {
  return window.toolsController.getDevices().map((device) => device.id).join("&") + getDisplay();
}

const getListUrl = () => {
  return getBaseUrl() + "?" + getList();
}

const updateLocation = (replace = false) => {
  Promise.resolve().then(() => {
    const url = window.getLayoutUrl();
    if (window.location.href != url && !window.loading)
    {
      if (replace)
        window.history.replaceState(window.getLayout(), "RETRO", url);
      else
        window.history.pushState(window.getLayout(), "RETRO", url);

      if (url.includes("?"))
        hideBlankSlate();
    }
  });
}

window.loadConfig = loadConfig;
window.getLayout = getLayout;
window.getList = getList;
window.getLayoutUrl = getLayoutUrl;
window.getListUrl = getListUrl;
window.addDevice = addDeviceAndUpdateLocation;

module.exports = { addDevice, getLayoutUrl, getListUrl, loadConfig, updateLocation, addAll }
