
        import * as module0 from './controllers/device_controller.js';import * as module1 from './controllers/device_picker_controller.js';import * as module2 from './controllers/display_selector_controller.js';import * as module3 from './controllers/expander_controller.js';import * as module4 from './controllers/inspector_controller.js';import * as module5 from './controllers/move_controller.js';import * as module6 from './controllers/tools_controller.js';import * as module7 from './controllers/viewport_controller.js'
        const modules = {
            "./controllers/device_controller.js": module0,
            "./controllers/device_picker_controller.js": module1,
            "./controllers/display_selector_controller.js": module2,
            "./controllers/expander_controller.js": module3,
            "./controllers/inspector_controller.js": module4,
            "./controllers/move_controller.js": module5,
            "./controllers/tools_controller.js": module6,
            "./controllers/viewport_controller.js": module7,
        };
        export default modules;
      