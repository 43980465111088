import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = { persist: { type: Boolean, default: true } };

  calculateKey() {
    return (this.element.id || this.element.getElementsByTagName("h3")[0].innerText.toLowerCase()) + "-expanded";
  }

  connect() {
    window.expanders = (window.expanders || []);
    window.expanders.push(this);
    this.key = this.calculateKey();
    if (this.key != null && this.persistValue) {
      let expanded = window.localStorage.getItem(this.key);
      if (expanded != null) {
        if (expanded == "t")
          this.open();
        else
          this.close();
      }
    }
  }

  isOpen() {
    return !this.element.classList.contains("closed");
  }

  open() {
    this.element.classList.remove("closed");
    // close other expanders on small screens
    if (window.screen.width <= 768 || window.screen.height <= 768) {
      for (let expander of window.expanders) {
        if (expander != this && expander.isOpen()) {
          expander.close();
        }
      }
    }
  }

  close(move = true) {
    this.element.classList.add("closed");
  }

  toggle(event) {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
    window.localStorage.setItem(this.key, this.isOpen() ? "t" : "f");
  }
}
