var pixelsPerMM = null;

module.exports.getPixelsPerMM = () => {
  if (pixelsPerMM != null)
    return pixelsPerMM;

  var div = document.createElement('div');
  div.style.display = 'block';
  div.style.height = '100mm';
  document.body.appendChild(div);
  pixelsPerMM = div.offsetHeight / 100;
  div.parentNode.removeChild(div);
  return pixelsPerMM;
}

module.exports.rateLimit = (callback, interval) => {
  let timeout = null;
  let call = null;
  return (...args) => {
    // if nothing happened so far
    if (timeout == null) {
      // call the method straight away
      callback(...args);

      // and set a timer
      timeout = setTimeout(() => {
        // when the timer is done
        timeout = null;
        // if there is another call scheduled
        if (call != null) {
          // call that
          call(...args);
        }
      }, interval);
    } else {
      // if when the call happens the timeout is waiting
      // then set the callback to happen to the new method
      call = callback
    }
  }
}

module.exports.getBaseUrl = () => {
  return document.location.href.split("?")[0];
}

module.exports.showBlankSlate = () => {
  document.getElementById("welcome").classList.add("blank-slate");
  document.getElementById("welcome").classList.remove("closed");
}

module.exports.hideBlankSlate = () => {
  document.getElementById("welcome").classList.remove("blank-slate");
}
