import { Controller } from "@hotwired/stimulus"
import interact from "interactjs";
import { getPixelsPerMM } from "utils.js"

export default class extends Controller {
  connect() {
    this.panX = 0;
    this.panY = 0;
    this.zoom = 1;
    this.ppm = getPixelsPerMM();
    interact(document.getElementsByTagName("body")[0]).draggable({
      listeners: {
        start: this.startDrag.bind(this),
        move: this.dragMouseMove.bind(this)
      },
      ignoreFrom: ".panel",
      mouseButtons: 1 | 4
    });
    interact(document.getElementsByTagName("body")[0]).gesturable({
      listeners: {
        start: function (event) {
          this.startZoom = this.zoom;
        }.bind(this),
        move: function (event) {
          this.zoomAction(this.startZoom * event.scale, event.x0, event.y0);
          this.setTransform();
        }.bind(this)
      },
      ignoreFrom: ".panel"
    })
    document.onwheel = ((event) => { this.mouseWheel(event); });
    this.setTransform();
    // prevent weird middle click autoscroll thingie
    document.body.onmousedown = function(e) { if (e.button === 1) return false; }
  }

  dragMouseMove(event) {
    this.panTo(
      this.startPanX + (event.clientX - this.startX)/getPixelsPerMM(),
      this.startPanY + (event.clientY - this.startY)/getPixelsPerMM()
    );
  }

  panTo(x, y) {
    this.panX = x;
    this.panY = y;
    this.setTransform();
  }

  setTransform() {
    window.zoom = this.zoom;
    const translate = `translate(${this.panX}mm, ${this.panY}mm)`;
    const scale = `scale(${this.zoom})`;
    const transform = `${translate} ${scale}`;
    this.element.style.transform = transform;
  }

  mouseWheel(event) {
    console.log("mouseWheel: ");
    console.log(event);
    if (event.target.closest(".panel"))
      return;

    let amount = event.deltaY / 1000;
    if (event.shiftKey) {
      amount = amount / 3;
    }

    console.log("amount: " + amount);

    var z = 1;
    if (amount < 0) {
      z = this.zoom * (1 - amount);
    } else if (amount > 0) {
      z = this.zoom / (1 + amount);
    } else {
      return;
    }

    this.zoomAction(z, event.clientX, event.clientY);
  }

  zoomAction(z, x, y) {
    // where proportionally is the mouse on the background
    let mouseX = (x / getPixelsPerMM() - this.panX) / this.zoom;
    let mouseY = (y / getPixelsPerMM() - this.panY) / this.zoom;

    this.zoom = z;

    // where proportionally is the mouse on the background now
    let mouseX2 = (x / getPixelsPerMM() - this.panX) / this.zoom;
    let mouseY2 = (y / getPixelsPerMM() - this.panY) / this.zoom;
    // what is the difference
    let dx = mouseX2 - mouseX
    let dy = mouseY2 - mouseY

    // adjust pan accounting for zoom
    this.panX += dx * this.zoom;
    this.panY += dy * this.zoom;

    this.setTransform();
  }

  startDrag(event) {
    if (!(event.button == 1 || event.button == 0))
      return;

    this.startPanX = this.panX;
    this.startPanY = this.panY;
    this.startX = event.clientX;
    this.startY = event.clientY;
  }
}
