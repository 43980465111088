import { Controller } from "@hotwired/stimulus"
import { updateLocation } from "devices.js"

export default class extends Controller {
  static targets = [ "scaling", "platform" ];

  connect() {
    window.displayController = this;
    this.set();
  }

  getPlatform() {
    return this.platformTarget.value;
  }

  getScaling() {
    return this.scalingTarget.value;
  }

  setPlatform(val = "details") {
    this.platformTarget.value = val;
    this.set();
  }

  setScaling(val = "fit") {
    this.scalingTarget.value = val;
    this.set();
  }

  set() {
    document.body.className = `${this.scalingTarget.value} ${this.platformTarget.value}`;
  }

  change() {
    this.set();
    updateLocation();
  }
}
