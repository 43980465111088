import "index.scss"
import "ui.scss"
import "device.scss"
import "mobile.scss"
import { Application } from "@hotwired/stimulus"
import { Liquid } from "liquidjs"
import { loadConfig } from "devices.js"
import controllers from "./controllers/**/*.{js,js.rb}"
import { hideBlankSlate, showBlankSlate } from "./utils"
import interact from 'interactjs'

window.interact = interact
window.liq = Liquid;

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

window.Stimulus = Application.start()

Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename.replace("./controllers/", "")
      .replace(/[_-]controller..*$/, "")
      .replace("_", "-")
      .replace("/", "--")

    Stimulus.register(identifier, controller.default)
  }
})


let engine = new Liquid({
  extname: '.html',
  root: ['templates/'],
  cache: true
});
window.engine = engine;

engine.registerFilter('screenDefinition', (screen) => {
  let definition = `s${screen.res.w}x${screen.res.h}`;

  if (screen.pal != null && screen.pal != "full")
    definition = definition + "x" + screen.pal
  return definition;
});

window.waitForTools = () => {
  return new Promise( (resolve, reject) => {
    var waited = 0;
    const check = () => {
      waited += 100;
      if (waited > 5000) {
        reject();
        return;
      }
      if (window.toolsController != null)
        resolve();
      else
        window.setTimeout(check, 100);
    }
    window.setTimeout(check, 100);
  })
};

fetch("devices.json")
  .then((response) => response.json())
  .then((data) => {
    let h = document.location.host;
    if (h == "retrosizer.com" || h == "localhost:4000" || h == "10.1.1.223:4000")
      window.devices = data;
    window.deviceIds = {};
    for (var device of Object.values(window.devices)) {
      window.deviceIds[device.code] = device.id;
    }
    if (document.location.href.includes("?")) {
      window.waitForTools().then(() => loadConfig(document.location.href.split("?")[1], true, true));
      sa_event("arrive-with-link");
    } else {
      showBlankSlate();
      sa_event("arrive-at-root");
    }
  });

window.addEventListener('popstate', function(event) {
  if (event) {
    window.loading = true;
    if (event.state == null) {
      window.toolsController.removeAll(false);
      if (document.location.href.includes("?")) {
        hideBlankSlate();
      } else {
        showBlankSlate();
      }
    } else {
      loadConfig(event.state);
    }
    window.loading = false;
  }
});

window.v = "0.8.01";
