import { Controller } from "@hotwired/stimulus"
import { getLayoutUrl, getListUrl, updateLocation, addAll } from "devices.js"
import { pack, stack, rack } from 'pack';

export default class extends Controller {

  connect() {
    window.toolsController = this;
  }

  getDevices() {
    return [...document.getElementsByClassName("device")].map((element) => element.deviceController).filter((e) => e);
  }

  removeAll(pushState = true) {
    for (var deviceController of this.getDevices()) {
      deviceController.remove();
    }
    if (pushState)
      updateLocation();
  }

  addAllDevices() {
    this.removeAll(false);
    addAll();
  }

  toggleAll() {
    const devices = this.getDevices().filter((device) => device.canOpen());
    if (devices.length == 0)
      return;
    const open = !devices[0].isOpen();
    for (let device of devices) {
      if (open && ! device.isOpen()) {
        device.open();
      } else if (!open && device.isOpen()) {
        device.close();
      }
    }
    updateLocation();
  }

  shareList() {
    navigator.clipboard.writeText(getListUrl());
  }

  shareLayout() {
    navigator.clipboard.writeText(getLayoutUrl());
  }

  packDevices(saveState = true) {
    return this.arrangeDevices(pack, saveState);
  }

  stackDevices(saveState = true) {
    return this.arrangeDevices(stack, saveState);
  }

  rackDevices(saveState = true) {
    return this.arrangeDevices(rack, saveState);
  }

  arrangeDevices(func, saveState = true) {
    let padding = 4;

    let boxes = []
    for (let device of this.getDevices()) {
      let box = device.getSize();
      box.device = device;
      box.w = box.w + padding * 2;
      box.h = box.h + padding * 2;
      boxes.push(box);
    }

    func(boxes);
    for (let box of boxes) {
      box.device.element.moveController.moveTo(box.x + padding, box.y + padding);
    }
    if (saveState)
      updateLocation();
  }
}
